//@ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { App } from "./App";

// console.disableYellowBox = true;
console.log = () => {};
console.warn = () => {};
console.info = () => {};

const Root = (props) => {
  return <App />;
};
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
