import React, { useContext, useEffect } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { ToastContainer } from "react-toastify";
import { ViewportProvider } from "./providers/viewport";
import { LoadAssetsV } from "./providers/loadassets";

import { GraphqlServer } from "./servers/graphql.server";
import { HttpServer } from "./servers/http.server";
import { ThemeContext, ThemeName, ThemeProvider } from "./providers/theme";
import { VlinderApp } from "./services/vlinder-app.service";
import config from "./config/default.config.json";
import { AppNavigator } from "./AppNavigator";
import { LOGO_STATIC_URI } from "./services/load-asset.service";
import { AuthService } from "./services/auth-service/auth.service";
import { RingLevelService } from "./handlers/ring-level-handlers";

const _setUpServers = () => {
  const options = {
    httpUri: config.servers.graphql.uri,
  };
  GraphqlServer.getInstance(options);
  HttpServer.getInstance();
};

_setUpServers();

const fonts = {
  "SFProText-Bold": require("./assets/fonts/SF-Pro-Text-Bold.otf"),
  "SFProText-Semibold": require("./assets/fonts/SF-Pro-Text-Semibold.otf"),
  "SFProText-Regular": require("./assets/fonts/SF-Pro-Text-Regular.otf"),
  "Ubuntu-Bold": require("./assets/fonts/Ubuntu-Bold.ttf"),
  "Ubuntu-Medium": require("./assets/fonts/Ubuntu-Medium.ttf"),
  "Ubuntu-Regular": require("./assets/fonts/Ubuntu-Regular.ttf"),
};
//Load all images here before app launches

const assets: number[] = [LOGO_STATIC_URI];

const AppV = () => {
  console.log("appV");
  useContext(ThemeContext);
  useEffect(() => {
    const themeProvider = ThemeProvider.getInstance();
    themeProvider.switchTheme(ThemeName.Vlinder);
  }, []);
  return (
    <>
      <AppNavigator />
    </>
  );
};

const _setUpBindings = () => {
  AuthService.getInstance();

  console.log("App started");

  //Set up services / Extension point
};

const _ringLevel = async () => {
  console.log("ringLevel Handler =>");
  const ringLevelMessageHandler = RingLevelService.getInstance();
  await ringLevelMessageHandler.handleMessage({});
};

const handleRingLevelUp = async () => {
  await _setUpBindings();
  await _ringLevel();
};

export const CONTEXT_BUFFER = "app.ctx.buffer";
export const GRAPHQL_CLIENT = "app.servers.graphql.client";
export const app = new VlinderApp();
export const ctx = VlinderApp.ctx;

export const App = () => {
  return (
    <>
      <style type="text/css">
        {`
          @font-face {
            font-family: 'MaterialIcons';
            src: url(${require("react-native-vector-icons/Fonts/MaterialIcons.ttf")}) format('truetype');
          }

          @font-face {
            font-family: 'FontAwesome';
            src: url(${require("react-native-vector-icons/Fonts/FontAwesome.ttf")}) format('truetype');
          }

          @font-face {
            font-family: 'AntDesign';
            src: url(${require("react-native-vector-icons/Fonts/AntDesign.ttf")}) format('truetype');
          }

          @font-face {
            font-family: 'Ionicons';
            src: url(${require("react-native-vector-icons/Fonts/Ionicons.ttf")}) format('truetype');
          }

          @font-face {
            font-family: 'MaterialCommunityIcons';
            src: url(${require("react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf")}) format('truetype');
          }

          @font-face {
            font-family: 'Octicons';
            src: url("https://a248.e.akamai.net/assets.github.com/fonts/octicons/octicons-regular-webfont.eot?ade8e027");
 
          }
        `}
      </style>
      <ThemeProvider>
        <ViewportProvider>
          <ApolloProvider client={GraphqlServer.getInstance()?.client}>
            <LoadAssetsV
              {...{ assets, fonts }}
              performRingLevelUp={handleRingLevelUp}
            >
              <AppV />
            </LoadAssetsV>
          </ApolloProvider>
        </ViewportProvider>
      </ThemeProvider>
    </>
  );
};
