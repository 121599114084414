import { Assets } from "@react-navigation/stack";
import React, { Suspense, lazy } from "react";

const NetworkOperatorScreen = lazy(
  () => import("../../../screens/NetworkOperator")
);
const OrganisationScreen = lazy(() => import("../../../screens/Organization"));
const AnalyticsScreen = lazy(() => import("../../../screens/Analytics"));
const ProductScreen = lazy(() => import("../../../screens/Products"));
const ProductDetailScreen = lazy(
  () => import("../../../screens/ProductDetail")
);
const GtinDetailScreen = lazy(() => import("../../../screens/GtinDetail"));
const BatchDetailScreen = lazy(() => import("../../../screens/BatchDetail"));
const HelperScreen = lazy(() => import("../../../screens/Helper"));
const TransactionScreen = lazy(
  () => import("../../../screens/TransactionsTable")
);
const CampaignScreen = lazy(() => import("../../../screens/Campaigns"));
const AssociatedOrgScreen = lazy(
  () => import("../../../screens/AssociatedOrgs")
);
const AssociatedOrgDetailScreen = lazy(
  () => import("../../../screens/AssociatedOrgDetails")
);
const PersonaScreenDesc = lazy(() => import("../../../screens/PersonasDesc"));
const PersonaScreen = lazy(() => import("../../../screens/Personas"));
const PersonaScreenTable = lazy(() => import("../../../screens/PersonasTable"));
const ProvenanceScreen = lazy(() => import("../../../screens/Provenance"));
const NewCampaignScreen = lazy(() => import("../../../screens/NewCampaigns"));
const NewProvenanceTemplateScreen = lazy(
  () => import("../../../screens/NewProvenance")
);
const ProvenanceDetailScreen = lazy(
  () => import("../../../screens/ProvenanceDetails")
);
const ApiSetupScreen = lazy(() => import("../../../screens/ApiSetup"));
const ApiSetupDetailScreen = lazy(
  () => import("../../../screens/ApiSetupDetail")
);
const ManageTemplateScreen = lazy(
  () => import("../../../screens/ManageTemplate")
);
const NewProductScreen = lazy(() => import("../../../screens/NewProduct"));
const AssociateBatchScreen = lazy(
  () => import("../../../screens/AssociateBatch")
);
const TransactionTableScreen = lazy(
  () => import("../../../screens/TransactionsBrandTable")
);

const FeedbackScreen = lazy(() => import("../../../screens/Feedback"));
const TransactionDetailScreen = lazy(
  () => import("../../../screens/TransactionDetails")
);
const AssociateProvenance = lazy(
  () => import("../../../screens/ProvenanceAssociation")
);
export const routes = [
  { path: "/manufacturer", Component: NetworkOperatorScreen },
  { path: "/organization", Component: OrganisationScreen },
  { path: "/analytics", Component: AnalyticsScreen },
  { path: "/products", Component: ProductScreen },
  { path: "/products/detail", Component: ProductDetailScreen },
  { path: "/products/new-product", Component: NewProductScreen },
  { path: "/gtins/detail", Component: GtinDetailScreen },
  { path: "/batches/detail", Component: BatchDetailScreen },
  { path: "/transactions", Component: TransactionScreen },
  { path: "/transactionsdetails", Component: TransactionDetailScreen },
  { path: "/transactions/brandprotection", Component: TransactionTableScreen },
  { path: "/campaigns", Component: CampaignScreen },
  { path: "/campaigns/new-campaign", Component: NewCampaignScreen },
  { path: "/associatedorganisations", Component: AssociatedOrgScreen },
  {
    path: "/associatedorganisations/detail",
    Component: AssociatedOrgDetailScreen,
  },
  { path: "/personas", Component: PersonaScreenDesc },
  { path: "/personas/template", Component: PersonaScreen },
  { path: "/personas/table", Component: PersonaScreenTable },
  { path: "/provenance", Component: ProvenanceScreen },
  { path: "/provenance/detail", Component: ProvenanceDetailScreen },
  { path: "/api-setup", Component: ApiSetupScreen },
  { path: "/api-setup/detail", Component: ApiSetupDetailScreen },
  { path: "/provenance/association", Component: AssociateProvenance },
  { path: "/provenance/manage-templates", Component: ManageTemplateScreen },
  { path: "/provenance/new-template", Component: NewProvenanceTemplateScreen },
  { path: "/gtins/associate-batch", Component: AssociateBatchScreen },
  { path: "/helper", Component: HelperScreen },
  { path: "/feedback", Component: FeedbackScreen },
];
